// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/signup'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_APPLICATION = {
  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  patients: {
    root: path(ROOTS_DASHBOARD, '/patients'),
    new: path(ROOTS_DASHBOARD, '/patients/new'),
    edit: path(ROOTS_DASHBOARD, '/patients/:id/edit'),
    anamnesis: {
      new: path(ROOTS_DASHBOARD, '/patients/:id/anamnesis/new'),
      edit: path(ROOTS_DASHBOARD, '/patients/:id/anamnesis/:anamnesisId/edit'),
      root: path(ROOTS_DASHBOARD, '/patients/:id/anamnesis/:anamnesisId'),
    },
    treatments: {
      new: path(ROOTS_DASHBOARD, '/patients/:id/treatments/new'),
      edit: path(ROOTS_DASHBOARD, '/patients/:id/treatments/:treatmentId/edit'),
      root: path(ROOTS_DASHBOARD, '/patients/:id/treatments/:treatmentId'),
    },
    documents: {
      new: path(ROOTS_DASHBOARD, '/patients/:id/documents/new'),
      edit: path(ROOTS_DASHBOARD, '/patients/:id/documents/:documentId/edit'),
      root: path(ROOTS_DASHBOARD, '/patients/:id/documents/:documentId'),
    },
    detail: path(ROOTS_DASHBOARD, '/patients/:id'),
  },
  user: {
    settings: path(ROOTS_DASHBOARD, '/user/settings/:tab'),
    subscription: {
      thankYou: path(ROOTS_DASHBOARD, '/user/subscription/thank-you/:subscriptionType'),
    },
    subscriptions: path(ROOTS_DASHBOARD, '/user/subscriptions/:id'),
  },
  completeProfile: path(ROOTS_DASHBOARD, '/complete-profile'),
  faqs: path(ROOTS_DASHBOARD, '/faqs'),
  calendar: {
    root: path(ROOTS_DASHBOARD, '/calendar'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

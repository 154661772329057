import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import SubscriptionDetails from 'pages/user/SubscriptionDetails';
import DashboardLayout from 'layouts/dashboard';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
// components
import LoadingScreen from 'components/LoadingScreen';
import { WaReviewRedirect } from 'components/WaReviewRedirect';
import { WaNumberRedirect } from 'components/WaNumberRedirect';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default function Router() {
  return useRoutes([
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Dashboard />, index: true }],
    },
    {
      path: 'patients',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <PatientsList />, index: true },
        { path: 'new', element: <PatientNew /> },
        { path: ':id/edit', element: <PatientEdit /> },
        { path: ':id/anamnesis/new', element: <AnamnesisNew /> },
        { path: ':id/anamnesis/:anamnesisId/edit', element: <AnamnesisEdit /> },
        { path: ':id/anamnesis/:anamnesisId', element: <AnamnesisDetail /> },
        { path: ':id/treatments/new', element: <TreatmentsNew /> },
        { path: ':id/treatments/:treatmentId/edit', element: <TreatmentEdit /> },
        { path: ':id/treatments/:treatmentId', element: <TreatmentDetail /> },
        { path: ':id/documents/:documentId/edit', element: <DocumentEdit /> },
        { path: ':id/documents/new', element: <DocumentsNew /> },
        { path: ':id', element: <PatientDetail /> },
      ],
    },
    {
      path: 'calendar',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Calendar />, index: true }],
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'subscription/thank-you/:subscriptionType', element: <ThankYouSubscription /> },
        {
          path: 'subscriptions/:subscriptionType',
          element: <SubscriptionDetails />,
        },
        { path: 'settings', element: <UserAccount />, index: true },
        { path: 'settings/:tab', element: <UserAccount /> },
      ],
    },
    {
      path: 'complete-profile',
      element: (
        <AuthGuard>
          <CompleteProfile />
        </AuthGuard>
      ),
    },
    {
      path: 'faqs',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <FaqsList />, index: true }],
    },
    {
      path: 'public',
      children: [{ path: 'cancel-event', element: <CancelEvent /> }],
    },
    // Note: We don't use the firebase hosting redirects for these redirects because the firebase hosting not support query params
    {
      path: 'api/wa',
      children: [
        { path: 'review', element: <WaReviewRedirect /> },
        { path: ':number', element: <WaNumberRedirect /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'signup',
          element: <Signup />,
        },
        { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
        { path: '/', element: <Navigate to="/login" /> },
      ],
    },
    { path: '500', element: <Page500 /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Signup = Loadable(lazy(() => import('../pages/auth/Signup')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CompleteProfile = Loadable(lazy(() => import('../pages/auth/CompleteProfile')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Public
const CancelEvent = Loadable(lazy(() => import('../pages/public/CancelEvent')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

// Patients
const PatientsList = Loadable(lazy(() => import('../pages/patients/PatientsList')));
const PatientDetail = Loadable(lazy(() => import('../pages/patients/PatientDetail')));
const PatientNew = Loadable(lazy(() => import('../pages/patients/PatientNew')));
const PatientEdit = Loadable(lazy(() => import('../pages/patients/PatientEdit')));
const AnamnesisNew = Loadable(lazy(() => import('../pages/patients/AnamnesisNew')));
const AnamnesisEdit = Loadable(lazy(() => import('../pages/patients/AnamnesisEdit')));
const AnamnesisDetail = Loadable(lazy(() => import('../pages/patients/AnamnesisDetail')));
const TreatmentsNew = Loadable(lazy(() => import('../pages/patients/TreatmentsNew')));
const TreatmentEdit = Loadable(lazy(() => import('../pages/patients/TreatmentEdit')));
const TreatmentDetail = Loadable(lazy(() => import('../pages/patients/TreatmentDetail')));
const DocumentsNew = Loadable(lazy(() => import('../pages/patients/DocumentsNew')));
const DocumentEdit = Loadable(lazy(() => import('../pages/patients/DocumentEdit')));

// User
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const ThankYouSubscription = Loadable(lazy(() => import('../pages/user/ThankYouSubscription')));

// Calendar
const Calendar = Loadable(lazy(() => import('../pages/calendar/Calendar')));

// FAQS
const FaqsList = Loadable(lazy(() => import('../pages/faqs/FaqsList')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
